import { useInsertionEffect } from "react"

const isInsert = new Set()

const useCrossStyle = (effect) => {
    
    useInsertionEffect(() => {
        if(!isInsert.has(effect)){
            isInsert.add(effect)
        }

    },[])

    return effect

}

export default useCrossStyle