import React from "react";


const List = ({
    list1, list2, list3, list4, list5,list15,
    list6, list7,list8,rule,className
}) => {
    return (
        <>
            {
                (rule == "list1") &&

                <ul>
                    <li className={className}>{list1}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className={className}>{list7}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className={className}>{list8}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className={className}>{list6}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <li className={className}>{list4}</li>&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <li className={className}>{list5}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className={className}>{list2}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className={className}>{list15}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                    <li className={className}>{list3}</li>&nbsp;&nbsp;&nbsp;&nbsp;
                </ul>
            }
            
        </>
    );
}

export default List;