import Arkos from './Pages/Arkos';
import AvisAuxClients from './Pages/AvisAuxClients';
import RsCom from './Pages/RsCom';
import RsHcm from './Pages/RsHcm';
import RsMusic from './Pages/RsMusic';
import RsProduction from './Pages/RsProduction';
import "./Style/style.css"
import Histoire from './Pages/Histoire';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import ContactUs from './Pages/ContactUs';

const Router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to={'/royal-sale-production'}/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production",
        element: <RsProduction/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/rs-hcm",
        element: <RsHcm/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/rs-com",
        element: <RsCom/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/rs-music",
        element: <RsMusic/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/arkos",
        element: <Arkos/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/histoire",
        element: <Histoire/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/avis-aux-clients",
        element: <AvisAuxClients/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "/royal-sale-production/contactez-nous",
        element: <ContactUs/>,
        errorElement: <NotFoundPage/>
    },
    {
        path: "*",
        element: <NotFoundPage/>
    }
])

export default Router