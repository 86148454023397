import React, { useEffect, useState } from "react";
import Style from "../Style/Style";
import axios from "../api/axios";
// import axios form 'axios';

const Video = ({
    rule,src,videoA
}) => {


    return (
        <>
            {
                (rule === "video1") &&

                (<video
                    autoPlay
                    loop
                    muted
                    className={"elementHeader1"}
                >
                    <source src={src}
                    type={"video/mp4"}></source>
                </video>)

            }

            {
                (rule === "video3") &&

                (<video
                    autoPlay
                    loop
                    muted
                    className={"elementHeader3"}
                >
                    <source src={src}
                    type={"video/mp4"}></source>
                </video>)

            }
            
            {
                rule === "video2" &&
                (<video  
                    autoPlay
                    loop
                    muted
                    className={`${videoA} elementHeader3`}
                    title="RS_cam_prod"
                >
                    <source src={"../../videos/RS_CAMPROD_23_VERSION_SITE.mp4"}
                    type={"video/mp4"}></source>
                    
                </video>)
                
            }

            {
                rule === "video6" &&
                (
                    <video  
                        autoPlay
                        loop
                        muted
                        className={`${videoA} elementHeader3`}
                        title="RS_CONTACT" 
                    > 
                        <source src={"../../videos/RS_CONTACT.mp4"}
                        type={"video/mp4"}></source>
                        
                    </video>
                )
                
            }
        </>
    );
}

export default Video;