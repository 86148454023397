import React from "react";
import { useState } from "react";
import LinkRoyal from "./LinkRoyal";
import Image from "./Image";


const ScrollButton = ({
    scrButton, style, srcOver, srcOut, rule,
}) => {

    const [form,setForm] = useState({
        scrBt: {
            display: "block",
            padding: "5px 5px 5px 5px",
            color: "black",
            position: "relative",
        },
    })

    if (rule == "scrollButton") {
        return (
            <section
                className={style}
                onMouseOver={srcOver}
                onMouseOut={srcOut}
            >
                <div
                    className="d-block position-relative text-black rounded"
                    // style={form.scrBt}
                >
                    <LinkRoyal
                        scrButton={scrButton}
                        rule={"scrButton"}
                        children={
                            <>
                                <i className="bi bi-chevron-up text-black fs-5 i-scroll" />
                            </>
                        }
                    >
                    </LinkRoyal>
                </div>
            </section>
        )
    }

}

export default ScrollButton;