import useOperationEffect from "./useOperationEffect"
import useCrossStyle from "./useCrossStyle"

const useAnimate = (pA,pB) => {
    const test = useOperationEffect(pA,pB)
    const crossStyle = useCrossStyle(test)

    return crossStyle
}

export default useAnimate