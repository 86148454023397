import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AudioFile from "./AudioFile";
import Carousel from "./Carousel";
import Image from "./Image";
import { useRef } from "react";
import axios from "../api/axios";

const GridFlex = ({
    rule, content1, content2, content3, content4, content8,
    content9, content10, content11, content12, content13, content14,
    content16, content17, content18, content19,anony,crossStyle,crossStyleA,
    crossStyleC,crossStyleD,crossStyleE,crossStyleF,crossStyleB,
    crossStyleI,imageB,imageC,imageD,imageE,imageF,imageG,imageH,
    imageI,imageJ,imageK,imageL,imageM,imA,imB
}) => {

    const [myStyle1, setMyStyle1] = useState({
        myStyle: "grid2",
        myStyle1: "grid2Child1",
        myStyle2: "grid2Child2",
        myStyle3: "grid2Child3",
        myStyle4: "grid2Child4",
        myStyle2Card: "grid2Card",
        myStyle2Card1: "grid2CardChild1",
        myStyle2Card2: "grid2CardChild2",
        myStyle4Card: "grid4Card",
        myStyle4Card1: "grid4CardChild1",
        myStyle4Card2: "grid4CardChild2",
        myStyle6Card: "grid6Card",
        myStyle6Card1: "grid6CardChild1",
        myStyle6Card2: "grid6CardChild2",
        gcs: "grid2Cs",
        gcs1: "grid2CsChild1",
        gcs2: "grid2CsChild2",
        gcs3: "grid2CsChild3",
        gcs4: "grid2CsChild4",
        gcs5: "grid2CsChild5",
        gcs6: "grid2CsChild6",
        gcs7: "grid2CsChild7",
        gcs8: "grid2CsChild8",
        myStyle2A: "grid2A",
        myStyle2A1: "grid2AChild1",
        myStyle2A2: "grid2AChild2",
        myStyle2A3: "grid2AChild3",
        myStyle2A4: "grid2AChild4",
        myStyle3D: "grid3D",
        myStyle3D1: "grid3DChild1",
        myStyle3D2: "grid3DChild2",
        myStyle3D3: "grid3DChild3",
        myStyle3D4: "grid3DChild4",
        myStyle1D: "grid1D",
        myStyle1D1: "grid1DChild1",
        myStyle1D2: "grid1DChild2",
        myStyle1D3: "grid1DChild3",
        myStyle1D4: "grid1DChild4",
        myStyle1D5: "grid1DChild5",
        myStyle1D6: "grid1DChild6",
        // myStyle1D7: "grid1DChild7",
        // myStyle1D8: "grid1DChild8",
        myStyle1: "grid2Child1",
        myStyle2: "grid2Child2",
        myStyle3: "grid2Child3",
        myStyle4: "grid2Child4",
        myStyle5: "flex1",
        myStyle6: "flex2",
        myStyle7: "flex4",
        myStyle8: "flex5",
        bt1: "gridLinkChild1",
        bt2: "gridLinkChild2",
    })

    const [data,setData] = useState({
        fullName: '',
        emailContact: '',
        email: '',
        contentContact: '',
        error: '',
        message: '',
        status: false,
        textButton: "S\'abonner",
        textButtonContact: <button id="bta" type="submit" className="btn btn-dark col-12">
                                Envoyer
                            </button>,
        isChangeTextButton: false,
    })
    const [timeOutId, setTimeOutId] = useState(null)


    const hoverLink5 = () => {
        // setTimeOutId(() => setTimeout(() => {
        //     setMyStyle1({
        //         ...myStyle1,
        //         myStyle1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/CLIENTS1.png)",
        //         backgroundPosition: "center",opacity: "0.5"}
        //     })
        // }, 0))
    }

    const outLink5 = () => {
        // clearTimeout(timeOutId)

        // setMyStyle1({
        //     ...myStyle1,
        //     myStyle1: {gridArea: "--var(ch1)",backgroundImage: "url(../../images/CLIENTS1.png)",
        //     backgroundPosition: "center",},
        // })
    }
    const hoverLink6 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle2: 'myStyle2-a',
        })
    }

    const outLink6 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle2: 'myStyle2-b',
        })
    }
    const hoverLink7 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle3: 'myStyle3-a',
        })
    }

    const outLink7 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle3: 'myStyle3-b',
        })
    }

    const hoverLink9 = () => {

        setMyStyle1({
            ...myStyle1,
            myStyle5: 'myStyle5-a',
        })
    }


    const outLink9 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle5: 'myStyle5-b',
        })
    }


    const hoverLink10 = () => {

        setMyStyle1({
            ...myStyle1,
            myStyle6: 'myStyle6-a',
        })
    }


    const outLink10 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle6: 'myStyle6-b',
        })
    }


    const hoverLink11 = () => {

        setMyStyle1({
            ...myStyle1,
            myStyle7: 'myStyle7-a',
        })
    }


    const outLink11 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle7: 'myStyle7-b',
        })

    }


    const hoverLink12 = () => {

        setMyStyle1({
            ...myStyle1,
            myStyle8: 'myStyle8-a',
        })
    }


    const outLink12 = () => {
        setMyStyle1({
            ...myStyle1,
            myStyle8: 'myStyle8-b',
        })
    }

    const hoverBt1 = () => {

        setMyStyle1({
            ...myStyle1,
            bt1: 'bt1-a',
        })
    }


    const outBt1 = () => {
        setMyStyle1({
            ...myStyle1,
            bt1: 'bt1-b',
        })
    }

    const hoverBt2 = () => {

        setMyStyle1({
            ...myStyle1,
            bt2: 'bt2-a',
        })
    }


    const outBt2 = () => {
        setMyStyle1({
            ...myStyle1,
            bt2: 'bt2-b',
        })
    }

    const myLoading = (text,delay) => {
        let i = 0
        if(i < delay){
            setTimeout(() => {
                setData({isChangeTextButton: true, textButton: text})
            }, 0);
            setTimeout(() => {
                setData({isChangeTextButton: true, textButton: text})
            }, 1000);
            i++
        }
    }


    const myLoadingContact = (text,delay) => {
        let i = 0
        if(i < delay){
            setTimeout(() => {
                setData({isChangeTextButton: true, textButtonContact: text})
            }, 0);
            setTimeout(() => {
                setData({isChangeTextButton: true, textButtonContact: text})
            }, 1000);
            i++
        }
    }

    const csrfToken = () => axios.get('sanctum/csrf-cookie')

    return (
        <>
            {
                (rule == "grid1") &&

                <section className={"grid1"}>
                    <div
                        className={"grid1Child1"}
                    >
                        <div
                            className={myStyle1.myStyle5}
                            onMouseOver={hoverLink9}
                            onMouseOut={outLink9}
                        >
                            <div
                                className={"flex1Child1"}
                            >
                                <div
                                    className={"grid4"}
                                >
                                    <Link
                                        to={"/"}
                                        className={"grid4Child1 hover-grid"}
                                    >
                                        Service Arkos
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className={"grid4Child2 hover-grid"}
                                    >
                                        Service Skyforce
                                    </Link>
                                </div>
                            </div>
                            <Link
                                to={"/"}
                                className={"flex1Child2 hover-grid"}
                            >
                                Service Luxevision
                            </Link>
                        </div>
                    </div>
                    <div className={"grid1Child2"}>
                        <div
                            className={myStyle1.myStyle6}
                            onMouseOver={hoverLink10}
                            onMouseOut={outLink10}
                        >
                            <div className={"flex2Child1"}>
                                <div className={"grid5"}>
                                    <Link
                                        to={"/"}
                                        className={"grid5Child1 hover-grid"}
                                    >
                                        Faire carrière
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className={"grid5Child2 hover-grid"}
                                    >
                                        Streaming RSM
                                    </Link>
                                </div>
                            </div>
                            <div className={"flex2Child2"}>
                                <div className={"grid6"}>
                                    <Link
                                        to={"/"}
                                        className={"grid6Child1 hover-grid"}
                                    >
                                        Réserver
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className={"grid6Child2 hover-grid"}
                                    >
                                        Média RSM
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={"grid1Child3"}
                    >
                        <div
                            className={myStyle1.myStyle7}
                            onMouseOver={hoverLink11}
                            onMouseOut={outLink11}
                        >
                            <Link
                                to={"/"}
                                className={"flex4Child2 hover-grid"}
                            >
                                Services professionnels <br />de communication
                            </Link>
                        </div>
                    </div>
                    <div className={"grid1Child4"}>
                        <div
                            className={myStyle1.myStyle8}
                            onMouseOver={hoverLink12}
                            onMouseOut={outLink12}
                        >
                            <Link
                                to={"/"}
                                className={"flex5Child2 hover-grid"}
                            >
                                Façonnez votre identité <br />haut de gamme
                            </Link>
                        </div>
                    </div>
                </section>
            }

            {
                (rule == "grid2A") &&

                
                <section className="container py-4 text-center">
                    <div className={`col-md-12 px-3`}>
                        <div className="row d-flex align-items-center">
                            <div className={`col-md-6 px-0 ${imageB}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/CONTENU_DU_SITE2.png"
                                >
                                </img>
                            </div>
                            <div className={`col-md-6 px-3 py-5 text-white ${crossStyleC}`}>
                                <h2  className={`col-md-12 h2`}>{content8}</h2>
                                <p  className={`col-md-12 mt-4 px-3`}>{content9}</p>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-12 px-3`}>
                        <div className="row d-flex align-items-center">
                            <div className={`col-md-6 px-3 py-5 text-white ${crossStyleD}`}>
                                <h2  className={`col-md-12 h2`}>{content10}</h2>
                                <p  className={`col-md-12 mt-4 px-0 text-size`}>{content11}</p>
                            </div>
                            <div className={`col-md-6 px-0 ${imageE}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/CONTENU_DU_SITE1.png"
                                >
                                </img>
                            </div>
                            
                        </div>
                    </div>
                </section>

            }


            {
                rule == "grid31D" && 
                (
                    <section className={`container text-center py-5 mb-5 text-white ${crossStyleB}`}>
                        <div className="row">
                            <div className="col">
                                <a className="nav-link d-flex flex-column" target="_blank" aria-current="whatsapp" href={`https://wa.me/${content8}`}>
                                    <i className="bi bi-whatsapp fs-1 text-success"></i>
                                    <h2 className="h2">{content8}</h2>
                                    <p className="h5">{content9}</p>
                                </a>
                            </div>
                            <div className="col">
                                <a className="nav-link d-flex flex-column" target="_blank" aria-current="email" href={`mailto:${content11}`}>
                                    <i className="bi bi-envelope-at-fill fs-1 text-primary"></i>
                                    <h2 className="h2">{content10}</h2>
                                    <p className="h5">{content11}</p>
                                </a>
                            </div>
                        </div>
                    </section>
                )
            }



            {
                rule == "grid81D" && 
                (
                    <section className={`container text-white py-5 mt-5 ${crossStyleA}`}>
                        <div className="row d-flex align-items-center justify-items-center  row-cols-1 row-cols-sm-1 row-cols-md-3">
                            <div className="col-md-6 mb-5 text-start">
                                <div className="col">
                                    <a className="nav-link" target="_blank" aria-current="email" href={`mailto:${content9}`}>
                                        <span><i className="bi bi-envelope-paper-fill fs-3 text-primary"></i></span>&nbsp;&nbsp;
                                        <strong className="h4">{content9}</strong>
                                    </a>
                                </div>
                                
                                <div className="col">
                                    <a className="nav-link" target="_blank" aria-current="tel" href={`https://wa.me/${content10}`}>
                                        <span><i className="bi bi-whatsapp fs-3 text-success"></i></span>&nbsp;&nbsp;
                                        <strong className="h4">{content10}</strong>
                                    </a>
                                </div>
                                
                                <div className="col">
                                    <a className="nav-link" href="#">
                                        <span><i className="bi bi-clock-fill fs-3 text-warning"></i></span>&nbsp;&nbsp;
                                        <strong className="h4">{content11}</strong>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 text-black">
                                <form 
                                    className="form-group mx-5 px-3 py-4 bg-white rounded-3"
                                    onSubmit={async(e) => {
                                        e.preventDefault()

                                        myLoadingContact(
                                            <button className="btn btn-dark col-12" type="button" disabled>
                                                <span role="status">Envoi...</span>
                                                <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                            </button>
                                            ,2
                                        )

                                        document.getElementById('bta').setAttribute('disabled',true)

                                        await csrfToken()
                                        await axios.post('api/rs/contact',{
                                            fullName: data.fullName,
                                            email: data.emailContact,
                                            message: data.contentContact,
                                        })
                                        .then(response => {
                                            if(response.data.success){
                                                setData({
                                                    fullName: '',
                                                    emailContact: '',
                                                    contentContact: '',
                                                    message: <p className="alert alert-primary text-center">
                                                                Nous avons bien reçu votre message
                                                            </p>,
                                                    status: false,
                                                    textButtonContact: <button 
                                                                            type="submit"
                                                                            className="btn btn-dark col-12"
                                                                            id="bta"
                                                                        >
                                                                            Envoyer
                                                                        </button>,
                                                })
                                                document.getElementById('bta').removeAttribute('disabled')
                                            }
                                            else{
                                                setData({
                                                    error: <p className="alert alert-danger text-center">Veuillez reprendre l'opération</p>,
                                                    status: true,
                                                    textButtonContact: <button 
                                                                            type="submit"
                                                                            className="btn btn-dark col-12"
                                                                            id="bta"
                                                                        >
                                                                            Envoyer
                                                                        </button>,
                                                })
                                            }
                                            
                                            setTimeout(() => {
                                                setData({
                                                    message: "",
                                                    status: false,
                                                    textButtonContact: <button 
                                                                            type="submit"
                                                                            className="btn btn-dark col-12"
                                                                            id="bta"
                                                                        >
                                                                            Envoyer
                                                                        </button>,
                                                })
                                            }, 4000);
                                        })
                                        .catch(error => {
                                            // const res = error.response
                                            // if(res.data && res.data.status && res.data.status === 422){
                                            //     console.log(res.data.error)
                                            // }
                                        })

                                    }}
                                >
                                    <h2 className="h2 mb-2">
                                        Envoie-nous un message
                                    </h2>
                                    { 
                                        data.status ? 
                                        data.error : 
                                        data.message
                                    }
                                    <div className="row d-flex flex-row mb-3">
                                        <div className="col flex-grow">
                                            <label 
                                                htmlFor="exampleInputFullName" 
                                                className="form-label"
                                            ></label>
                                            <input 
                                                type="text" 
                                                name="fullName"
                                                value={data.fullName}
                                                className="form-control" 
                                                onChange={(e) => 
                                                    // console.log(e.target.value)
                                                    setData({...data,fullName: e.target.value,
                                                        textButtonContact: <button id="bta" type="submit" className="btn btn-dark col-12">
                                                                                Envoyer
                                                                            </button>
                                                    })
                                                }
                                                id="exampleInputFullName" 
                                                required 
                                                placeholder="Nom et prénoms"
                                            />
                                            <div></div>
                                        </div>
                                        <div className="col flex-grow">
                                            <label 
                                                htmlFor="exampleInputEmail" 
                                                className="form-label"
                                            ></label>
                                            <input 
                                                type="email" 
                                                name="email"
                                                value={data.emailContact}
                                                onChange={(e) => 
                                                    // console.log(e.target.value)
                                                    setData({
                                                        ...data,
                                                        emailContact: e.target.value,
                                                        textButtonContact: 
                                                            <button type="submit" id="bta" className="btn btn-dark col-12">
                                                                Envoyer
                                                            </button>
                                                    })
                                                }
                                                className="form-control" 
                                                id="exampleInputEmail" 
                                                required placeholder="Adresse email"
                                            />
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="mb-2 col flex-grow">
                                        <label 
                                            htmlFor="exampleInputMessage" 
                                            className="form-label"
                                        ></label>
                                        <textarea 
                                            name="emailContent"
                                            value={data.contentContact}
                                            onChange={(e) => 
                                                // console.log(e.target.value)
                                                setData({
                                                    ...data,
                                                    contentContact: e.target.value,
                                                    textButtonContact: <button id="bta" type="submit" className="btn btn-dark col-12">
                                                            Envoyer
                                                        </button>
                                                })
                                            }
                                            className="form-control mb-3" 
                                            id="exampleInputMessage" 
                                            required placeholder="Votre message"
                                            rows={"7"}
                                        />
                                        <div></div>
                                    </div>
                                    <div className="col mb-3 flex-grow">
                                        {data.isChangeTextButton ? data.textButtonContact : data.textButtonContact}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                )
            }

            {
                (rule == "grid3D") &&
                
                <section className="container py-5 text-center">
                <div className={`col-md-12 px-3`}>
                    <div className="row d-flex align-items-center">
                        <div className={`col-md-6 px-0 ${imageI}`}>
                            <img
                                className={`img-fluid`}
                                src="../images/CONTENU_DU_SITE22.png"
                            >
                            </img>
                        </div>
                        <div className={`col-md-6 px-3 py-5 text-white ${crossStyleB}`}>
                            <h2  className={`col-md-12 h2`}>{content8}</h2>
                            <p  className={`col-md-12 mt-4 px-3`}>{content9}</p>
                        </div>
                    </div>
                </div>
                <div className={`col-md-12 px-3`}>
                    <div className="row d-flex align-items-center">
                        <div className={`col-md-6 px-3 py-5 text-white ${crossStyleC}`}>
                            <h2  className={`col-md-12 h2`}>{content10}</h2>
                            <p  className={`col-md-12 mt-4 px-0 p text-size`}>{content11}</p>
                        </div>
                        <div className={`col-md-6 px-0 ${imageJ}`}>
                            <img
                                className={`img-fluid`}
                                src="../images/CONTENU_DU_SITE21.png"
                            >
                            </img>
                        </div>
                        
                    </div>
                </div>
            </section>

            }

            {
                (rule == "grid1D") &&

                <section className="container py-5 text-center">
                    <div className={`col-md-12 px-3`}>
                        <div className="row d-flex align-items-center">
                            <div className={`col-md-6 px-0 ${imageD}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/rs-prod2.png"
                                >
                                </img>
                            </div>
                            <div className={`col-md-6 px-3 py-5 text-white ${crossStyleB}`}>
                                <h2  className={`col-md-12 h2`}>{content8}</h2>
                                <p  className={`col-md-12 mt-4 px-3`}>{content9}</p>
                                <p className={"col-md-12 py-4"}>
                                    <Link
                                        to={"/royal-sale-production/arkos"}
                                        className={myStyle1.bt2}
                                        id="hover-grid-link-child2"
                                        onMouseOver={hoverBt2}
                                        onMouseOut={outBt2}
                                    >
                                        DÉCOUVREZ ARKOS
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-12 px-3`}>
                        <div className="row d-flex align-items-center">
                            <div className={`col-md-6 px-3 py-5 text-white ${crossStyleC}`}>
                                <h2  className={`col-md-12 h2`}>{content10}</h2>
                                <p  className={`col-md-12 mt-4 px-0 text-left p text-size`}>{content11}</p>
                                
                                <p className={"col-md-12 py-4 "}>
                                    <Link
                                        to={"/royal-sale-production/rs-hcm"}
                                        id="hover-grid-link-child1"
                                        className={myStyle1.bt1}
                                        onMouseOver={hoverBt1}
                                        onMouseOut={outBt1}
                                    >
                                        DEVENEZ UNIQUE AVEC H.C.M
                                    </Link>
                                </p>
                            </div>
                            <div className={`col-md-6 px-0 ${imageE}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/rs-prod3.png"
                                >
                                </img>
                            </div>
                            
                        </div>
                    </div>
                    <div className={`col-md-12 px-3`}>
                        <div className="row d-flex align-items-center">
                            <div className={`col-md-6 px-3 ${imageF}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/rs-prod4.png"
                                >
                                </img>
                            </div>
                            <div className={`col-md-6 px-3 py-5 text-white ${crossStyleD}`}>
                                <h2  className={`col-md-12 h2`}>{content16}</h2>
                                <p  className={`col-md-12 mt-4 px-3`}>{content17}</p>
                                <p className={"col-md-12 py-4"}>
                                    <Link
                                        to={"/royal-sale-production/rs-music"}
                                        className={myStyle1.bt2}
                                        id="hover-grid-link-child2"
                                        onMouseOver={hoverBt2}
                                        onMouseOut={outBt2}
                                    >
                                        DÉCOUVREZ RSM
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            }

            {
                (rule == "grid2B") &&

                <section className="container py-5 text-center">
                    <div className={`col-md-12 px-3`}>
                        <div className="row d-flex align-items-center">
                            <div className={`col-md-6 align-items-md-center px-0 py-5 text-white ${crossStyleB}`}>
                                <h2  className={`col-md-12 h2`}>{content8}</h2>
                                <p  className={`col-md-12 mt-4 px-3 p`}>{content9}</p>
                            </div>
                            <div className={`col-md-6 px-0 ${imageM}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/CONTENU_DU_SITE13.png"
                                >
                                </img>
                            </div>
                            
                        </div>
                    </div>
                    <div className={`col-md-12 px-3`}>
                    <div className="row d-flex align-items-center">
                            <div className={`col-md-6 px-0 ${imageK}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/CONTENU_DU_SITE16.png"
                                >
                                </img>
                            </div>
                            <div className={`col-md-6 ps-3 py-5 text-white ${crossStyleC}`}>
                                <h2  className={`col-md-12 h2`}>{content10}</h2>
                                <p  className={`col-md-12 mt-4 px-3 text-size`}>{content11}</p>
                                <p className={"col-md-12 py-4"}>
                                    <Link
                                        to={"/royal-sale-production/contactez-nous"}
                                        className={myStyle1.bt2}
                                        id="hover-grid-link-child2"
                                        onMouseOver={hoverBt2}
                                        onMouseOut={outBt2}
                                    >
                                        DÉMARQUEZ-VOUS
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-12 px-3`}>
                        <div className="row d-flex align-items-center">
                            <div className={`col-md-6 px-0 py-5 text-white ${crossStyleD}`}>
                                <h2  className={`col-md-12`}>{content13}</h2>
                                <p  className={`col-md-12 mt-4 px-3 text-left p`}>{content14}</p>
                                
                                <p className={"col-md-12 py-4"}>
                                    <Link
                                        to={"/royal-sale-production/contactez-nous"}
                                        id="hover-grid-link-child1"
                                        className={myStyle1.bt1}
                                        onMouseOver={hoverBt1}
                                        onMouseOut={outBt1}
                                    >
                                        FRANCHISSEZ LE CAP
                                    </Link>
                                </p>
                            </div>
                            <div className={`col-md-6 px-0 ${imageL}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/CONTENU_DU_SITE14.png"
                                >
                                </img>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {
                (rule == "grid9") &&

                <section className="container text-center">
                    <div className={`col-md-12 mb-4 px-3`}>
                        <div className="row">
                            <div className={`col-md-6 px-0 ${imageB}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/rs-prod5.png"
                                >
                                </img>
                            </div>
                            <div className={`col-md-6 px-0 ${imageC}`}>
                                <img
                                    className={`img-fluid`}
                                    src="../images/CONTENUESITE.png"
                                >
                                </img>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-12`}>
                        <div className="row py-2">
                            <div className={`col-md-6 px-0 mb-2 text-white ${crossStyleC}`}>
                                <h2  className={`col-md-12 h2`}>{content8}</h2>
                                <p  className={`col-md-12 mt-3 px-3 p`}>{content9}</p>
                                
                                <p className={"col-md-12 mt-4"}>
                                    <Link
                                        to={"/royal-sale-production/contactez-nous"}
                                        id="hover-grid-link-child1"
                                        className={myStyle1.bt1}
                                        onMouseOver={hoverBt1}
                                        onMouseOut={outBt1}
                                    >
                                        CONTACTEZ-NOUS
                                    </Link>
                                </p>
                            </div>
                            <div className={`col-md-6 px-0 mb-2 mt-1 text-white ${crossStyleC}`}>
                                <h2  className={`col-md-12 h2`}>{content10}</h2>
                                <p  className={`col-md-12 mt-3 px-3 text-size`}>{content11}</p>
                                <p className={"col-md-12 mt-4"}>
                                    <Link
                                        to={"/royal-sale-production/avis-aux-clients"}
                                        className={myStyle1.bt2}
                                        id="hover-grid-link-child2"
                                        onMouseOver={hoverBt2}
                                        onMouseOut={outBt2}
                                    >
                                        AVIS CLIENTS
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {
                (rule == "grid8A") &&

                <section className="container">
                    <div className={`col-md-12 mb-4 px-3`}>
                        <div className="row">
                            <div className={`col-md-6 px-0 ${imageH}`}>
                                <img
                                    className={`img-fluid`}
                                    src={imA}
                                >
                                </img>
                            </div>
                            <div className={`col-md-6 px-0 ${imageG}`}>
                                <img
                                    className={`img-fluid`}
                                    src={imB}
                                >
                                </img>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-12`}>
                        <div className="row">
                            <div className={`col-md-6 px-0 text-white mb-2 ${crossStyleD}`}>
                                <h2  className={`col-md-12 text-center h2`}>{content8}</h2>
                                <p  className={`col-md-12 mt-4 px-3 text-center p`}>{content9}</p>
                                
                                <p className={"col-md-12 mt-4 mb-4 text-center"}>
                                    <Link
                                        to={"/royal-sale-production/contactez-nous"}
                                        id="hover-grid-link-child1"
                                        className={myStyle1.bt1}
                                        onMouseOver={hoverBt1}
                                        onMouseOut={outBt1}
                                    >
                                        CONTACTEZ-NOUS
                                    </Link>
                                </p>
                            </div>
                            <div className={`col-md-6 px-0 text-white ${crossStyleD}`}>
                                <h2  className={`col-md-12 text-center h2`}>{content10}</h2>
                                <p  className={`col-md-12 mt-4 px-3 text-center`}>{content11}</p>
                                <p className={"col-md-12 mt-4 mb-3 text-center"}>
                                    <Link
                                        to={"/royal-sale-production/avis-aux-clients"}
                                        className={myStyle1.bt2}
                                        id="hover-grid-link-child2"
                                        onMouseOver={hoverBt2}
                                        onMouseOut={outBt2}
                                    >
                                        AVIS CLIENTS
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            }


            {
                (rule == "grid8A-A") &&

                <section className="container">
                    <div className={`col-md-12 mb-4 px-3`}>
                        <div className="row">
                            <div className={`col-md-6 px-0 ${imageH}`}>
                                <img
                                    className={`img-fluid`}
                                    src={imA}
                                >
                                </img>
                            </div>
                            <div className={`col-md-6 px-0 ${imageG}`}>
                                <img
                                    className={`img-fluid`}
                                    src={imB}
                                >
                                </img>
                            </div>
                        </div>
                    </div>
                    <div className={`col-md-12`}>
                        <div className="row">
                            <div className={`col-md-6 px-0 text-white ${crossStyleD}`}>
                                <h2  className={`col-md-12 text-center h2`}>{content8}</h2>
                                <p  className={`col-md-12 mt-3 px-3 text-center p`}>{content9}</p>
                                
                                <p className={"col-md-12 mt-5 text-center"}>
                                    <Link
                                        to={"/royal-sale-production/contactez-nous"}
                                        id="hover-grid-link-child1"
                                        className={myStyle1.bt1}
                                        onMouseOver={hoverBt1}
                                        onMouseOut={outBt1}
                                    >
                                        CONTACTEZ-NOUS
                                    </Link>
                                </p>
                            </div>
                            <div className={`col-md-6 px-0 text-white mb-3 mt-1 ${crossStyleD}`}>
                                <h2  className={`col-md-12 text-center h2`}>{content10}</h2>
                                <p  className={`col-md-12 mt-4 px-3 text-center text-size`}>{content11}</p>
                                <p className={"col-md-12 mt-4 text-center"}>
                                    <Link
                                        to={"/royal-sale-production/avis-aux-clients"}
                                        className={myStyle1.bt2}
                                        id="hover-grid-link-child2"
                                        onMouseOver={hoverBt2}
                                        onMouseOut={outBt2}
                                    >
                                        AVIS CLIENTS
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            }

            {
                (rule == "grid11") &&

                <section className={`container ${crossStyleC} my-5`} >
                    <div className="col-md-12">
                       <div className={"row d-flex justify-content-center"}>
                            <div className={"col-md-6 text-white text-center"}>
                                <h2  className={`h1`}>{content12}</h2>
                                <p  className={`p mt-3 text-size`}>{content11}</p>
                                <p  className={`p mt-4`}>
                                    <Link
                                        to={"/royal-sale-production/contactez-nous"}
                                        id="hover-grid-link-child1"
                                        className={myStyle1.bt1}
                                        onMouseOver={hoverBt1}
                                        onMouseOut={outBt1}
                                    >
                                        CONTACTEZ-NOUS
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            }

            {
                (rule == "grid31") &&

                <section className={`container ${crossStyleD} my-5`} >
                    <div className="col-md-12">
                       <div className={"row d-flex flex-column align-items-center text-center"}>
                            <div className={"col text-white text-center mb-3"}>
                                <h2 className="h1">{content12}</h2>
                                <p>{content11}</p>
                            </div>
                            <div className={"col text-white text-center"}>
                                <Link
                                    to={"/royal-sale-production/contactez-nous"}
                                    id="hover-grid-link-child1"
                                    className={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                >
                                    CONTACTEZ-NOUS
                                </Link>
                            </div>
                            <div className={"col text-white text-center mt-5"}>
                                <h2  className={`h1`}>{content10}</h2>
                            </div>
                        </div>
                    </div>
                </section>

            }

            {
                (rule == "grid10") &&

                <section className={`container ${crossStyleA} mt-5`} >
                    <div className="col-md-12">
                       <div className={"row d-flex flex-column justify-content-center text-center"}>
                            <div className={"col text-white mb-3"}>
                                <p>{content11}</p>
                            </div>
                            <div className={"col text-white mb-1"}>
                                <Link
                                    to={"/royalsaleofficial/avis-aux-clients"}
                                    id="hover-grid-link-child1"
                                    className={myStyle1.bt1}
                                    onMouseOver={hoverBt1}
                                    onMouseOut={outBt1}
                                >
                                    CLIQUEZ POUR VOIR NOTRE SAVOIR-FAIRE
                                </Link>
                            </div>
                            <div className={"col text-white mt-4"}>
                                <p  className={`h1`}>{content10}</p>
                            </div>
                        </div>
                    </div>
                </section>


            }

            {
                (rule == "grid20") &&

                <section className={`container ${crossStyleA} mt-5 text-center`} >
                    <div className="col-md-12">
                        <div className={"row d-flex flex-column align-items-center justify-content-center"}>
                                <div className={"col text-white mb-5"}>
                                    <h2 className="h1">{content10}</h2>
                                </div>
                                <div className={"col text-white"}>
                                    <a
                                        href={'https://www.instagram.com/royalsaleofficial/'}
                                        id="hover-grid-link-child1"
                                        target="_blank"
                                        className={myStyle1.bt1}
                                        onMouseOver={hoverBt1}
                                        onMouseOut={outBt1}
                                    >
                                        CLIQUEZ ICI
                                    </a>
                                </div>
                                <div className={"col text-white"}>
                                </div>
                            </div>
                        </div>
                    </section>
            }

            {
                (rule == "grid27") &&
                <>
                    <section className={`container ${crossStyleI}`}>
                        <div className="col-md-12 text-center text-white mt-5 mb-3">
                            <h2>
                                AU COEUR DE L'EXPÉRIENCE RS
                            </h2>
                        </div>

                        <div className="text-center">
                        <div className="row d-flex justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 py-5">
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/Directrice Marketing - LUST SECRET.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2 fs-6  text-md-center">Directrice - LUST SECRET</span>
                                        <AudioFile
                                            rule={"web-api-audio1"}
                                            MySrc={"../../filezic/pa.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/ZOULY_RS_COMMUNITY.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">ZOULY</span>
                                        <AudioFile
                                            rule={"web-api-audio2"}
                                            MySrc={"../../filezic/pb.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/J_E.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">J.E</span>
                                        <AudioFile
                                            rule={"web-api-audio7"}
                                            MySrc={"../../filezic/J_E.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 py-5">
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/EMMANUELLA_RS_COMMUNITY.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">EMMANUELA</span>
                                        <AudioFile
                                            rule={"web-api-audio3"}
                                            MySrc={"../../filezic/pc.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/NINON_ET_MICHAELLE.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">NINON & MICHAELLE</span>
                                        <AudioFile
                                            rule={"web-api-audio4"}
                                            MySrc={"../../filezic/pd.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/BTK_FITNESS.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">BTK FITNESS</span>
                                        <AudioFile
                                            rule={"web-api-audio8"}
                                            MySrc={"../../filezic/BTK.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 py-5">
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/ORISON_PRESIDENT_CASINO.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">PRESIDENT - ORISON</span>
                                        <AudioFile
                                            rule={"web-api-audio5"}
                                            MySrc={"../../filezic/pe.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/Fondatrice - BLACK PEARL.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">Fondatrice BLACK PEARL</span>
                                        <AudioFile
                                            rule={"web-api-audio6"}
                                            MySrc={"../../filezic/pf.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/Freudo.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">FREUDO</span>
                                        <AudioFile
                                            rule={"web-api-audio9"}
                                            MySrc={"../../filezic/FREUDO.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className={`col-md-12 text-center mt-5 mb-5 ${crossStyleI}`}>
                            <Link
                                to={"/royal-sale-production/avis-aux-clients"}
                                id="hover-grid-link-child1"
                                className={myStyle1.bt1}
                                onMouseOver={hoverBt1}
                                onMouseOut={outBt1}
                            >
                                DÉCOUVREZ PLUS DE TÉMOIGNAGES
                            </Link>
                        </div>

                    </section>
                </>
            }

            {
                (rule == "grid10B") &&

                <section className={`container ${crossStyleF} mt-5`} >
                    <div className="col-md-12">
                       <div className={"row"}>
                            <div className={"col-md-3"}></div>
                            <div className={"col-md-6 text-white text-center"}>
                                <div  className={`col-md-12`}>
                                    <h2 className={`h2`}>{content10}</h2>
                                </div>

                                <div className="col-md-12">
                                    <form
                                        onSubmit={
                                            async(e) => {
                                            e.preventDefault()
                                            
                                            myLoading('Envoi',2)

                                            document.getElementById('bt').setAttribute('disabled',true)
                                            
                                            await csrfToken()
                                            await axios.post('api/rs/newsletters',{
                                                email: data.email
                                            })
                                            .then(response => {
                                                if(response.data.success){
                                                    setData({email: '',message: "Abonnement réussi",status: false,textButton: "S'abonner"})
                                                    document.getElementById('bt').removeAttribute('disabled')
                                                }
                                                else{
                                                    setData({error: "Veuillez reprendre l'opération",status: true,textButton: "S'abonner"})
                                                }
                                                
                                                setTimeout(() => {
                                                    setData({message: "",status: false,textButton: "S'abonner"})
                                                }, 4000);
                                            })
                                            .catch(error => {
                                                // const res = error.response
                                                // if(res.data && res.data.status && res.data.status === 422){
                                                //     console.log(res.data.error)
                                                // }
                                            })
                                        }}
                                        className="d-flex px-0 py-3"
                                    >
                                        <input 
                                            type="email" 
                                            name="email" 
                                            value={data.email} 
                                            onChange={(e) => setData({
                                                email:e.target.value,
                                                textButton: "S'abonner"
                                            })}
                                            className="form-control"
                                            id="newsletters"
                                            placeholder="votre adresse e-mail"
                                            aria-placeholder="votre adresse e-mail"
                                            required
                                        />
                                        &nbsp;
                                        <button 
                                            type="submit"
                                            className="btn btn-light col"
                                            id="bt"
                                        >
                                            {data.isChangeTextButton ? data.textButton : data.textButton}
                                        </button>
                                    </form>
                                </div>
                            </div>
                            { 
                                data.status ? 
                                <span className="alert alert-danger text-center">{data.error}</span> : 
                                <span className="text-white text-center">{data.message}</span>
                            }
                            <div className={"col-md-3"}></div>
                        </div>
                    </div>
                </section>
            }

            {
                (rule == "gridTem") &&

                <section className={`container py-5 ${crossStyleI}`}>
                    <div className="col-md-12 text-center text-white py-3">
                        <h2 className="h1">
                            POUR VOS TEMOIGNAGES
                        </h2>
                    </div>
                    
                    <div className="text-center ">
                        <div className="row d-flex justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 py-5">
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/Directrice Marketing - LUST SECRET.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2 fs-6  text-md-center">Directrice - LUST SECRET</span>
                                        <AudioFile
                                            rule={"web-api-audio1"}
                                            MySrc={"../../filezic/pa.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/ZOULY_RS_COMMUNITY.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">ZOULY</span>
                                        <AudioFile
                                            rule={"web-api-audio2"}
                                            MySrc={"../../filezic/pb.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/J_E.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">J.E</span>
                                        <AudioFile
                                            rule={"web-api-audio7"}
                                            MySrc={"../../filezic/J_E.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 py-5">
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/EMMANUELLA_RS_COMMUNITY.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">EMMANUELA</span>
                                        <AudioFile
                                            rule={"web-api-audio3"}
                                            MySrc={"../../filezic/pc.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/NINON_ET_MICHAELLE.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">NINON & MICHAELLE</span>
                                        <AudioFile
                                            rule={"web-api-audio4"}
                                            MySrc={"../../filezic/pd.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/BTK_FITNESS.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">BTK FITNESS</span>
                                        <AudioFile
                                            rule={"web-api-audio8"}
                                            MySrc={"../../filezic/BTK.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 py-5">
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/ORISON_PRESIDENT_CASINO.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">PRESIDENT - ORISON</span>
                                        <AudioFile
                                            rule={"web-api-audio5"}
                                            MySrc={"../../filezic/pe.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/Fondatrice - BLACK PEARL.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">Fondatrice BLACK PEARL</span>
                                        <AudioFile
                                            rule={"web-api-audio6"}
                                            MySrc={"../../filezic/pf.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row text-start">
                                    <div className="col-3 mt-1 text-center">
                                        <Image
                                            rule={"image12"}
                                            source={"../../images/Freudo.png"}
                                        >
                                        </Image>
                                    </div>
                                    <div className="col-9 text-start">
                                        <span className="text-white ms-2  text-md-center">FREUDO</span>
                                        <AudioFile
                                            rule={"web-api-audio9"}
                                            MySrc={"../../filezic/FREUDO.mp3"}
                                        >
                                        </AudioFile>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-md-12">
                        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
                            <div className="col">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/Directrice Marketing - LUST SECRET.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">Directrice Marketing LUST SECRET</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio1"}
                                                    MySrc={"../../filezic/pa.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/ZOULY_RS_COMMUNITY.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">ZOULY</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio2"}
                                                    MySrc={"../../filezic/pb.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/J_E.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">J.E</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio7"}
                                                    MySrc={"../../filezic/J_E.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/EMMANUELLA_RS_COMMUNITY.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">EMMANUELA</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio3"}
                                                    MySrc={"../../filezic/pc.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/NINON_ET_MICHAELLE.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">NINON & MICHAELLE</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio4"}
                                                    MySrc={"../../filezic/pd.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/BTK_FITNESS.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">BTK FITNESS</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio8"}
                                                    MySrc={"../../filezic/BTK.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/ORISON_PRESIDENT_CASINO.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">PRESIDENT CASINO - ORISON</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio5"}
                                                    MySrc={"../../filezic/pe.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/Fondatrice - BLACK PEARL.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">Fondatrice BLACK PEARL</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio6"}
                                                    MySrc={"../../filezic/pf.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col-2 mt-1 text-end">
                                            <Image
                                                rule={"image12"}
                                                source={"../../images/Freudo.png"}
                                            >
                                            </Image>
                                        </div>
                                        <div className="col-10">
                                            <p className="col-12 text-white ms-2">FREUDO</h2>
                                            <div className="col-12">
                                                <AudioFile
                                                    rule={"web-api-audio9"}
                                                    MySrc={"../../filezic/FREUDO.mp3"}
                                                >
                                                </AudioFile>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </section>
            }

            {
                (rule == "grid2") &&

                <section className={myStyle1.myStyle}>
                    <Link
                        to={"/"}
                        id="hover-grid"
                        className={myStyle1.myStyle1}
                    >
                    </Link>
                    <Link
                        to={"/"}
                        id="hover-grid"
                        className={myStyle1.myStyle2}
                    >
                    </Link>
                    <Link
                        to={"/"}
                        id="hover-grid"
                        className={myStyle1.myStyle3}
                    >
                    </Link>
                    <Link
                        to={"/"}
                        id="hover-grid"
                        className={myStyle1.myStyle4}
                    >
                    </Link>
                </section>
            }

            {
                (rule == "grid2V1") &&

                <section className={`container ${crossStyleE} my-4`}>
                    <div className={`col-md-12 mb-4 px-3`}>
                        <div className="row">
                            <div className={`col-md-6 px-0 ${imageH}`}>
                                <Link
                                    to={"/"}
                                    id="hover-grid"
                                >
                                    <img
                                        className="img-fluid"
                                        src="../images/HCMJ.png"
                                    >
                                    </img>
                                </Link>
                            </div>
                            <div className={`col-md-6 px-0 ${imageG}`}>
                                <Link
                                    to={"/"}
                                    id="hover-grid"
                                >
                                    <img
                                        className="img-fluid"
                                        src="../images/rsmusic2.png"
                                    >
                                    </img>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

            }

            {
                (rule == "grid2V4") &&

                <section className={`container ${crossStyleE} my-5`}>
                    <div className={`col-md-12 mb-4 px-3`}>
                        <div className="row">
                            <div className={`col-md-6 px-0 ${imageH}`}>
                                <Link
                                    to={"/"}
                                    id="hover-grid"
                                >
                                    <img
                                        className="img-fluid"
                                        src="../images/rsmusic2.png"
                                    >
                                    </img>
                                </Link>
                            </div>
                            <div className={`col-md-6 px-0 ${imageG}`}>
                                <Link
                                    to={"/"}
                                    id="hover-grid"
                                >
                                    <img
                                        className="img-fluid"
                                        src="../images/rscom-1.png"
                                    >
                                    </img>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            }

            {
                (rule == "grid2V6") &&

                <section className={`container ${crossStyleE} mb-3`}>
                    <div className={`col-md-12 mb-4 px-3`}>
                        <div className="row">
                            <div className={`col-md-6 px-0 ${imageH}`}>
                                <Link
                                    to={"/"}
                                    id="hover-grid"
                                >
                                    <img
                                        className="img-fluid"
                                        src="../images/rscom-1.png"
                                    >
                                    </img>
                                </Link>
                            </div>
                            <div className={`col-md-6 px-0 ${imageG}`}>
                                <Link
                                    to={"/"}
                                    id="hover-grid"
                                >
                                    <img
                                        className="img-fluid"
                                        src="../images/PRODUCT2.png"
                                    >
                                    </img>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

            }

            {
                (rule == "grid2Cs") &&

                <>
                    <Carousel
                        rule={"image-carousel"}
                        crossStyleB={crossStyleB}
                        crossStyleC={crossStyleC}
                        crossStyleD={crossStyleD}
                    >
                    </Carousel>
                </>


            }

            {
                (rule == "grid2Cs1") &&

                <>
                    <Carousel
                        rule={"video-carousel"}
                        crossStyleB={crossStyleB}
                        crossStyleC={crossStyleC}
                        crossStyleD={crossStyleD}
                    >
                    </Carousel>
                </>


            }

            {
                (rule == "grid3") &&

                <section className={"grid3"}>
                    <div className={"grid3Child1"}>
                        <p  className={` pCenter`}>{content8}</p>
                    </div>
                </section>
            }

            {
                (rule == "grid4") &&

                <section className={`container ${crossStyleA} my-4`} >
                    <div className="col-md-12">
                        <div className={"row d-flex justify-content-center"}>
                            <div className={"col text-white text-center"}>
                                <h2  className={`display-5`}>{content8}</h2>
                                <p  className={`p`}>{content9}</p>
                            </div>
                        </div>
                    </div>
                </section>

            }

            {
                (rule == "grid5C") &&

                <section className={`container ${crossStyleA} mt-5`} >
                    <div className="col-md-12">
                       <div className={"row d-flex flex-column justify-content-center text-center text-white"}>
                            <div className={"col"}>
                                <h2  className={`display-4`}>{content8}</h2>
                                <p  className={`p`}>{content9}</p>
                            </div>
                        </div>
                    </div>
                </section>

            }

            {
                (rule == "grid5C-A") &&

                <section className={`container ${crossStyleA} mt-5`} >
                    <div className="col-md-12">
                       <div className={"row d-flex flex-column justify-content-center text-center text-white"}>
                            <div className={"col"}>
                                <h2  className={`h1`}>{content8}</h2>
                                <p  className={`text-sm-start text-md-center text-lg-center text-xl-center`}>{content9}</p>
                            </div>
                        </div>
                    </div>
                </section>

            }

        </>
    );
}

export default GridFlex;






























































































































