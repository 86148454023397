import React, { useEffect, useRef, useState } from "react"
import GridFlex from "../Components/GridFlex"
import Image from "../Components/Image"
import Video from "../Components/Video"
import useAnimat from "../Components/useAnimat"


const Content = ({ rule }) => {
    const crossStyle = useAnimat('opace-yes','allStyle')
    const crossStyleA = useAnimat('opace-yes','allStyleA')
    const crossStyleB = useAnimat('opace-yes','allStyleB')
    const crossStyleC = useAnimat('opace-yes','allStyleC')
    const crossStyleD = useAnimat('opace-yes','allStyleD')
    const crossStyleE = useAnimat('opace-yes','allStyleE')
    const crossStyleF = useAnimat('opace-yes','allStyleF')
    const crossStyleI = useAnimat('opace-yes','allStyleI')
    const imageA = useAnimat('eH2','allStyleImgA')
    const videoA = useAnimat('opace-yes','allStyleVidA')
    const imageB = useAnimat('g9C1','allStyleImgB')
    const imageC = useAnimat('g9C2','allStyleImgC')
    const imageD = useAnimat('g1DC1','allStyleImgD')
    const imageE = useAnimat('g1DC4','allStyleImgE')
    const imageF = useAnimat('g1DC5','allStyleImgF')
    const imageG = useAnimat('g8AC1','allStyleImgG')
    const imageH = useAnimat('g8AC2','allStyleImgH')
    const imageI = useAnimat('g3DC1','allStyleImgI')
    const imageJ = useAnimat('g3DC4','allStyleImgJ')
    const imageK = useAnimat('g2BC1','allStyleImgK')
    const imageL = useAnimat('g2BC4','allStyleImgL')
    const imageM = useAnimat('g2BC6','allStyleImgM')

    return (
        <section>
            {
                rule == "Accueil" &&
                <>

                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"grid1"}
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"grid3"}
                        content8={
                            "UNIVERS"
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"grid2"}
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"grid3"}
                        content8={
                            "AllCheaper"
                        }
                    >
                    </GridFlex>

                    <Video
                        crossStyle={crossStyle}
                        rule={"video1"}
                        src={"https://video.wixstatic.com/video/b0dbc0_bdc99561c89044519d1f53b8950fdb7f/1080p/mp4/file.mp4"}
                    >
                    </Video>

                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"grid10B"}
                        content10={
                            "Abonnez-vous à notre newsletter"
                        }
                    >
                    </GridFlex>
                </>
            }

            {
                rule == "rs-music" &&
                <>
                    <Video
                        crossStyle={crossStyle}
                        rule={"video1"}
                        src={"https://video.wixstatic.com/video/b0dbc0_bc8c16afed914d1bb160d416932cdd10/720p/mp4/file.mp4"}
                    >
                    </Video>

                    <GridFlex
                        crossStyleA={crossStyleA}
                        rule={"grid4"}
                        content8={
                            "VOTRE PASSEPORT POUR LA GLOIRE MUSICALE"
                        }
                        content9={
                            <>
                                Plongez dans le monde enchanteur de l'art en devenant artiste, en partageant votre musique ou en <br/> écoutant des compositions d'exception.
                            </>
                        }
                    >
                    </GridFlex>

                    <Image
                        imageA={imageA}
                        rule={"image2"}
                        source={"../../images/rs-music1.jpg"}
                    // source={"https://static.wixstatic.com/media/b0dbc0_9c2ac7dcb29b4ac9a4a42f1df8cc66ce~mv2.png/v1/fill/w_1293,h_768,al_c,q_90,enc_auto/b0dbc0_9c2ac7dcb29b4ac9a4a42f1df8cc66ce~mv2.png"}
                    >
                    </Image>

                    <GridFlex
                        crossStyleC={crossStyleC}
                        rule={"grid11"}
                        content11={
                            "Avec notre expertise artistique sans faille et notre soutien audiovisuel infaillible, nous vous offrons un passeport de prestige pour une reconnaissance musicale mondiale. En travaillant main dans la main avec notre maison, vous bénéficierez de notre expérience et de notre savoir-faire pour créer des œuvres d'art musicales uniques et inoubliables. Nous sommes fiers de participer activement au développement de nos artistes, et notre engagement envers leur succès musical sera toujours notre priorité absolue. Rejoignez le cercle, et ensemble façonnons l'avenir de la musique. "
                        }
                        content12={
                            "LANCEZ VOTRE CARRIÈRE"
                        }
                    >
                    </GridFlex>

                    <Video
                        crossStyle={crossStyle}
                        rule={"video3"}
                        src={"https://video.wixstatic.com/video/b0dbc0_d3221b3321af451888aa44aaf64dc539/720p/mp4/file.mp4"}
                    >
                    </Video>


                    <GridFlex
                        crossStyleD={crossStyleD}
                        rule={"grid31"}
                        content10={
                            "AUTRES SERVICES"
                        }
                        content11={
                            <>
                                Conçu pour accueillir les plus grandes voix de notre temps, réservez dès maintenant votre séance <br/> exclusive et offrez à votre musique la reconnaissance qu'elle mérite.
                            </>
                        }
                        content12={
                            "RÉSERVEZ UNE SESSION"
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleE={crossStyleE}
                        rule={"grid2V6"}
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleF={crossStyleF}
                        rule={"grid10B"}
                        content10={
                            "Abonnez-vous à notre newsletter"
                        }
                    >
                    </GridFlex>
                </>
            }

            {
                rule == "arkos" &&
                <>
                    <Video
                        crossStyle={crossStyle}
                        rule={"video1"}
                        src={"https://video.wixstatic.com/video/b0dbc0_870ffcb25b28419ab32509965af9ae6e/720p/mp4/file.mp4"}
                    >
                    </Video>

                    <GridFlex
                        crossStyleA={crossStyleA}
                        rule={"grid4"}
                        content8={
                            "VOTRE ELITE AUDIOVISUELLE"
                        }
                        content9={
                            <>
                                Destiné à une clientèle en quête de l'excellence, le prestigieux service Arkos s'impose comme l'ultime outil de <br/> propulsion pour hisser vos projets cinématographiques au plus haut niveau de perfection.
                            </>
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleB={crossStyleB}
                        rule={"grid2A"}
                        content8={
                            <>
                                LES LÉGENDES SONT NÉES <br/> AVEC UNE PART D'AMBITION
                            </>
                        }
                        content9={
                            "Le service Arkos de la Royal Sale Production est né pour les plus intrépides et ambitieux de l'industrie. Il offre une opportunité unique pour les producteurs de repousser les frontières de l'innovation et de la créativité. Grâce à une expertise pointue et une approche personnalisée, Arkos redéfinit les limites de l'art de la production et élève les standards de qualité à des niveaux inégalés."
                        }
                        content10={
                            <>
                                L'EXCELLENCE AU SERVICE DE <br/> VOTRE VISION CRÉATIVE
                            </>
                        }
                        content11={
                            "Choisir Arkos, c'est s'offrir le luxe d'une équipe de spécialistes dévoués et passionnés. Avec une approche personnalisée et une attention minutieuse aux détails, Arkos inspire et crée des productions qui captivent les sens et éblouissent les spectateurs. Prenez le pas dès maintenant pour réaliser des productions mémorables et remarquables grâce à notre service de prestige."
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        imageB={imageB}
                        imageC={imageC}
                        crossStyleC={crossStyleC}
                        rule={"grid9"}
                        content8={
                            <>
                                L'EXCELLENCE AU SERVICE DE <br/> VOTRE VISION CRÉATIVE
                            </>
                        }
                        content9={
                            "Le service Arkos de la Royal Sale Production est né pour les plus intrépides et ambitieux de l'industrie. Il offre une opportunité unique pour les producteurs de repousser les frontières de l'innovation et de la créativité. Grâce à une expertise pointue et une approche personnalisée, Arkos redéfinit les limites de l'art de la production et élève les standards de qualité à des niveaux inégalés."
                        }
                        content10={
                            <>
                                L'OPINION DE NOS CLIENTS EST UN <br/> GAGE DE QUALITE
                            </>
                        }
                        content11={
                            "Choisir Arkos, c'est s'offrir le luxe d'une équipe de spécialistes dévoués et passionnés. Avec une approche personnalisée et une attention minutieuse aux détails, Arkos inspire et crée des productions qui captivent les sens et éblouissent les spectateurs. Prenez le pas dès maintenant pour réaliser des productions mémorables et remarquables grâce à notre service de prestige."
                        }
                    >
                    </GridFlex>

                    <Video
                        videoA={videoA}
                        rule={"video2"}
                    >
                    </Video>

                    <GridFlex
                        crossStyleI={crossStyleI}
                        rule={"grid10"}
                        content10={
                            "AUTRES SERVICES"
                        }
                        content11={
                            <>
                                Chaque projet est traité avec un souci méticuleux du détail et une <br /> passion sans faille pour l'art cinématographique. Si vous êtes en quête de <br /> la perfection dans le monde de l'audiovisuel, alors la maison RS est un <br /> choix incontournable.
                            </>
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleE={crossStyleE}
                        rule={"grid2V1"}
                    >
                    </GridFlex>
                      
                    <GridFlex
                        crossStyleF={crossStyleF}
                        rule={"grid10B"}
                        content10={
                            "Abonnez-vous à notre newsletter"
                        }
                    >
                    </GridFlex> 
                </>
            }

            {
                rule == "rs-com" &&
                <>
                    <Video
                        crossStyle={crossStyle}
                        rule={"video1"}
                        src={"https://video.wixstatic.com/video/b0dbc0_3928ff170c2241ba94360b395a2bcbc4/720p/mp4/file.mp4"}
                    >
                    </Video>

                    <GridFlex
                        crossStyleA={crossStyleA}
                        rule={"grid5C"}
                        content8={
                            "COMMUNIQUEZ AVEC IMPACT"
                        }
                        content9={
                            <>
                                La communication est la clé pour atteindre le pouvoir, que ce soit en le communicant avec puissance ou en <br/> devenant soi-même une puissance grâce à elle.
                            </>
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        imageI={imageI}
                        imageJ={imageJ}
                        crossStyleB={crossStyleB}
                        crossStyleC={crossStyleC}
                        rule={"grid3D"}
                        content8={
                            "LA 1ère PUISSANCE"
                        }
                        content9={
                            <p>(Détenez votre propre plateforme mondiale)<br /><br /> Prenez la tête du peloton et hissez-vous en tant que détenteur exclusif d'une gamme complète d'outils de communication professionnelle de pointe tels que le Sms Pro, le Rich Sms, le Flash Sms, le Voice Sms, l'E-mailing, la Voip, le Chatbot, le Crm d'appel et bien d'autres. Découvrez les composantes qui feront de votre futur plateforme mondiale un acteur incontournable dans le monde de la communication d'envergure internationale.</p>
                        }
                        content10={
                            "LA PUISSANCE SECONDAIRE"
                        }
                        content11={
                            "Si votre ambition est de vous distinguer dans le domaine de la communication en alliant force de frappe et efficacité, abonnez-vous à nos offres RS et accédez à une gamme de fonctionnalités avancées telles que le Sms Pro, le Rich Sms, le Flash Sms, l'E-mailing, la Voip, le VPN, et bien plus encore. Réalisez des campagnes publicitaires impactantes à l'echelle mondiale. Obtenez de la puissance pour gagner en puissance dès maintenant."
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleD={crossStyleD}
                        imageG={imageG}
                        imageH={imageH}
                        rule={"grid8A-A"}
                        content8={
                            <>
                                LA PUISSANCE DONT VOUS AVEZ <br/> TOUJOURS RÊVER
                            </>
                        }
                        content9={
                            "Joignez-vous dès maintenant à la bataille de la télécommunications grâce à notre panoplie de services de qualité supérieure. Érigez-vous en tant que puissance dans votre propre droit ou laissez-nous vous propulser vers les sommets de la réussite."
                        }
                        content10={
                            <>
                                L'OPINION DE NOS CLIENTS EST UN <br/> GAGE DE QUALITE
                            </>
                        }
                        content11={
                            "Nous sommes humbles devant les témoignages de nos clients et de notre communauté. Faites vous orienter par leur expérience édifiante et leurs éloges qui soulignent l'engagement envers notre dévouement à la perfection et à l'innovation continue."
                        }
                        imA={"../images/new_1.jpg"}
                        imB={"../images/CONTENU_DU_SITE25.png"}
                    >
                    </GridFlex>

                    <Video
                        videoA={videoA}
                        rule={"video2"}
                    >
                    </Video>

                    
                    <GridFlex
                        crossStyleI={crossStyleI}
                        rule={"grid10"}
                        content10={
                            "AUTRES SERVICES"
                        }
                        content11={
                            <>
                                Chaque projet est traité avec un souci méticuleux du détail et une <br /> passion sans faille pour l'art cinématographique. Si vous êtes en quête de <br /> la perfection dans le monde de l'audiovisuel, alors la maison RS est un <br /> choix incontournable.
                            </>
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleE={crossStyleE}
                        rule={"grid2V1"}
                    >
                    </GridFlex>
                   
                    <GridFlex
                        crossStyleF={crossStyleF}
                        rule={"grid10B"}
                        content10={
                            "Abonnez-vous à notre newsletter"
                        }
                    >
                    </GridFlex> 
                </>
            }

            {
                rule == "rs-hcm" &&
                <>
                    <Video
                        crossStyle={crossStyle}
                        rule={"video1"}
                        src={"https://video.wixstatic.com/video/b0dbc0_746456315a2740c5b0647a76ef291b03/720p/mp4/file.mp4"}
                    >
                    </Video>

                    <GridFlex
                        crossStyleA={crossStyleA}
                        rule={"grid4"}
                        content8={
                            "VOTRE MARQUE DE LUXE"
                        }
                        content9={
                            <>
                                Rejoignez le cercle restreint des marques d'exception, avec l'aide de RS HCM. Avec notre accompagnement <br/> personnalisé, votre marque sera sublimée et vous pourrez la positionner au sommet de son marché.
                            </>
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleB={crossStyleB}
                        crossStyleC={crossStyleC}
                        crossStyleD={crossStyleD}
                        imageK={imageK}
                        imageL={imageL}
                        imageM={imageM}
                        rule={"grid2B"}
                        content8={
                            "UN STYLE SANS COMPROMIS"
                        }
                        content9={
                            "Destiné aux entrepreneurs les plus exigeants, notre service High Class Media est synonyme d'exclusivité, de sophistication et de prestige. Que vous cherchiez à promouvoir votre entreprise, votre produit ou votre image personnelle nous vous offrons un accompagnement sur mesure dès l'incubation de votre idée, en passant par son élaboration, jusqu'à sa promotion."
                        }
                        content10={
                            "VOTRE GUIDE PERSONNEL"
                        }
                        content11={
                            "Persuader d'être l'arme la plus sûre pour l'imposance de votre marque, nous sommes à la fois un partenaire et un guide pour chaque entrepreneur ambitieux qui souhaite lancer une marque sur le marché avec assurance et élégance."
                        }
                        content13={
                            "DU PRESTIGE A L'EXCLUSIVITE"
                        }
                        content14={
                            "Avec High Class Media, passez des portes du prestige à l'élite exclusive. Conçu pour les marques les plus exigeantes, offrez-vous l'accès à un cercle sélect de personnes privilégiées qui partagent les mêmes goûts pour l'élégance et la qualité. L'expérience offerte par notre service est bien au-delà de la simple production ou de la promotion, elle est l'incarnation même de la sophistication et de l'excellence, pour une audience qui sait apprécier le raffinement et la distinction."
                        }
                    >
                    </GridFlex>

                    <Video
                        videoA={videoA}
                        rule={"video6"}
                    >
                    </Video>

                    <GridFlex
                        crossStyleI={crossStyleI}
                        rule={"grid10"}
                        content10={
                            "AUTRES SERVICES"
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleE={crossStyleE}
                        rule={"grid2V4"}
                    >
                    </GridFlex>
                    
                </>
            }

            {
                rule == "rs-production" &&
                <>
                    <div style={{marginTop: "75px"}}>
                        <Image
                            crossStyle={crossStyle}
                            rule={"image2"}
                            source={"../../images/rs-prod1.png"}
                        >
                        </Image>
                    </div>

                    {/* <Video
                    crossStyle={crossStyle}
                    crossStyleA={crossStyleA}
                        rule={"video1"}
                        src={"https://video.wixstatic.com/video/b0dbc0_3928ff170c2241ba94360b395a2bcbc4/720p/mp4/file.mp4"}
                    >
                    </Video> */}

                    <GridFlex
                        crossStyleA={crossStyleA}
                        rule={"grid5C"}
                        content8={
                            "LA GENÈSE DE L'EXCELLENCE"
                        }
                        content9={
                            <>
                                « A la maison RS, nous sommes fermement convaincus que l'ascension vers le royaume de l'excellence repose sur <br /> une quête incessante de la perfection. »
                                <br /><br />
                                (Le Producteur)
                            </>
                        }
                    >
                    </GridFlex>
          
                    <GridFlex
                        imageD={imageD}
                        imageE={imageE}
                        imageF={imageF}
                        crossStyleB={crossStyleB}
                        crossStyleC={crossStyleC}
                        crossStyleD={crossStyleD}
                        rule={"grid1D"}
                        content8={
                            "OSEZ L'EXCEPTIONNEL"
                        }
                        content9={
                            "En tant qu'entreprise aspirant à la perfection, vous recherchez une production de contenu distinguée, éblouissante et remarquable. Loin des productions traditionnelles, vous êtes en quête d'une expertise sur-mesure et d'une créativité inégalée. Nous sommes fiers de vous présenter Arkos, l'un de nos services les plus prestigieux, synonyme de raffinement et d'exclusivité. En choisissant Arkos, vous bénéficierez d'une offre d'une rare qualité, combinant nos équipements les plus performants et notre savoir-faire inimitable, pour une production de contenu qui transcendera vos attentes."
                        }
                        content10={
                            "AUTHENTIQUE A VIE"
                        }
                        content11={
                            "Si de simples prestations audiovisuelles ne sont pas à la hauteur de votre ambition, et que vous désirez hisser votre marque au firmament de l'élite exclusive des marques les plus prestigieuses, alors H.C.M est le service d'exception qui saura répondre à vos exigences. Grâce à High Class Media, nous accompagnons les marques émergentes en quête d'excellence, ainsi que les marques établies souhaitant parfaire leur image prestigieuse. Il va sans dire que l'engagement envers High Class Media se traduit par une offre personnalisée, extrêmement flexible, dédiée à l'édification d'une image de marque à la mesure de la luxuriance."
                        }
                        content16={
                            <>
                                VOUS AVEZ LA MUSIQUE, <br/>NOUS AVONS LA CLÉ DU SUCCÈS
                            </>
                        }
                        content17={
                            "Explorez l'univers envoûtant de l'art en vous élevant au rang d'artiste, en partageant votre musique avec une audience distinguée ou en découvrant des oeuvres musicales d'exception. Soyez le maître de votre destin artistique en vous associant à une maison de production qui a la capacité de faire briller votre musique de mille feux et de la propulser au-delà des frontières. Accédez à la consécration musicale grâce à RSM : carrière, studio, distribution."
                        }
                        content18={
                            "L'EXCELLENCE AU SERVICE DE VOTRE VISION CRÉATIVE"
                        }
                        content19={
                            "Choisir Arkos, c'est s'offrir le luxe d'une équipe de spécialistes dévoués et passionnés. Avec une approche personnalisée et une attention minutieuse aux détails, Arkos inspire et crée des productions qui captivent les sens et éblouissent les spectateurs. Prenez le pas dès maintenant pour réaliser des productions mémorables et remarquables grâce à notre service de prestige."
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleA={crossStyleA}
                        crossStyleD={crossStyleE}
                        imageG={imageG}
                        imageH={imageH}
                        rule={"grid8A"}
                        content8={
                            <>VOTRE ENTREPRISE AU CENTRE <br/> DE LA TÉLÉCOMMUNICATION</>
                        }
                        content9={
                            "Nous vous offrons l'opportunité de choisir entre embrasser la puissance ou être accompagné dans votre quête de suprématie. Plongez dans l'univers des télécommunications de manière exhaustive grâce à nos différentes offres disponibles dans pas moins de 219 pays à travers le globe."
                        }
                        content10={
                            <>UNE AURA DE RÉALITÉ QUI <br/> TRANSCENDE LE MYTHE</>
                        }
                        content11={
                            <>Plongez au cœur de l'histoire fascinante de la Royal Sale, imprégnée de l'essence unique de chacun de ses protagonistes. Découvrez les réponses aux questions qui vous taraudent : les raisons, les modalités, les moments-<br/>clés ainsi que la vision RS.</>
                        }
                        imA={"../images/new_1.jpg"}
                        imB={"../images/new_2.jpg"}
                    >
                    </GridFlex>

                    <Video
                        videoA={videoA}
                        rule={"video2"}
                    >
                    </Video>
                    
                    <GridFlex
                        crossStyleI={crossStyleI}
                        rule={"grid27"}
                        content10={
                            "AU COEUR DE L'EXPÉRIENCE RS"
                        }
                    >
                    </GridFlex>
                   

                    <GridFlex
                        crossStyleF={crossStyleF}
                        rule={"grid10B"}
                        content10={
                            "Abonnez-vous à notre newsletter"
                        }
                    >
                    </GridFlex> 

                    {/* <GridFlex
                        crossStyleF={crossStyleF}
                        rule={"grid10B16"}
                    >
                    </GridFlex> */}
                </>
            }

            {
                rule == "rs-contact" &&
                <>

                    <div className="py-5">
                        <GridFlex
                            crossStyleA={crossStyleA}
                            rule={"grid5C"}
                            content8={
                                "Contactez nous"
                            }
                            content9={
                                <>
                                    Envoyez votre message et notre équipe vous contactera prochainement.
                                </>
                            }
                        >
                        </GridFlex>
                    </div>
                    
                    <GridFlex
                        imageD={imageD}
                        imageE={imageE}
                        imageF={imageF}
                        crossStyleB={crossStyleB}
                        crossStyleC={crossStyleC}
                        crossStyleD={crossStyleD}
                        rule={"grid31D"}
                        content8={
                            "Whatsapp"
                        }
                        content9={
                            "+22954326619"
                        }
                        content10={
                            "E-mail"
                        }
                        content11={
                            "royalsaleofficiel@gmail.com"
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyleA={crossStyleA}
                        crossStyleD={crossStyleE}
                        imageG={imageG}
                        imageH={imageH}
                        rule={"grid81D"}
                        content9={
                            "royalsaleofficiel@gmail.com"
                        }
                        content10={
                            "+22954326619"
                        }
                        content11={
                            "8h - 18h"
                        }
                    >
                    </GridFlex>
                </>
            }

            {
                rule == "histoire" &&
                <>

                    <Image
                        crossStyle={crossStyle}
                        rule={"image4"}
                        source={"../../images/COUVERTURE_OFFICIELLE_PAGE_HISTOIRE.png"}
                    >
                    </Image>

                    <GridFlex
                        crossStyleF={crossStyleF}
                        rule={"grid10B"}
                        content10={
                            "NOUS CONTINUONS À L'ÉCRIRE AVEC DÉTERMINATION ET EXCELLENCE"
                        }
                    >
                    </GridFlex>
                </>
            }

            {
                rule == "avis-aux-clients" &&
                <>
                    <Video
                        crossStyle={crossStyle}
                        rule={"video1"}
                        src={"https://video.wixstatic.com/video/b0dbc0_358637938e0e43f5a5622e773f13c267/720p/mp4/file.mp4"}
                    >
                    </Video>

                    <GridFlex
                        crossStyleA={crossStyleA}
                        rule={"grid5C"}
                        content8={
                            "NOS PROJETS"
                        }
                        content9={
                            <>
                                Nous sommes investis corps et âme dans chacune de nos réalisations, travaillant avec ardeur et diligence pour <br /> produire des œuvres de qualité supérieure. Ci-dessous est présentée une représentation visuelle exhaustive de <br /> l'intégralité de nos initiatives en matière d'audiovisuel.
                            </>
                        }
                    >
                    </GridFlex>


                    <GridFlex
                        crossStyleB={crossStyleB}
                        crossStyleC={crossStyleC}
                        crossStyleD={crossStyleD}
                        // crossStyle={crossStyle}
                        // crossStyle={crossStyle}
                        rule={"grid2Cs"}
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"grid5C-A"}
                        content8={
                            "MERCI A VOUS"
                        }
                        content9={
                            <>
                                Au sein de la RS nous accordons une grande importance à chaque projet que nous entreprenons, c'est pourquoi nous sommes <br/> reconnaissants envers ceux qui ont pris le temps de partager leur expérience avec nous. Nous sommes convaincus que la <br/> satisfaction de nos clients et de notre communauté est le reflet de notre dévouement  à offrir un service haut de gamme.
                            </>
                        }
                    >
                    </GridFlex>

                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"gridTem"}
                        content8={
                            "MERCI A VOUS"
                        }
                        content9={
                            <>
                                Au sein de la RS nous accordons une grande importance à chaque projet que nous entreprenons, c'est pourquoi nous sommes reconnaissants envers ceux qui ont pris le temps de partager leur expérience avec nous. Nous sommes convaincus que la satisfaction de nos clients et de notre communauté est le reflet de notre dévouement  à offrir un service haut de gamme.
                            </>
                        }
                    >
                    </GridFlex>

                    {/* <GridFlex
                        crossStyleB={crossStyleB}
                        crossStyleC={crossStyleC}
                        crossStyleD={crossStyleD}
                        // crossStyle={crossStyle}
                        // crossStyle={crossStyle}
                        rule={"grid2Cs1"}
                    >
                    </GridFlex> */}

                    <Video
                        crossStyle={crossStyle}
                        rule={"video2"}
                    >
                    </Video>

                    
                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"grid20"}
                        content10={
                            "POUR D'AVANTAGE DE TEMOIGNAGE"
                        }
                    // content11={
                    //     "Chaque projet est traité avec un souci méticuleux du détail et une passion sans faille pour l'art cinématographique. Si vous êtes en quête de la perfection dans le monde de l'audiovisuel, alors la maison RS est un choix incontournable."
                    // }
                    >
                    </GridFlex>
                   

                    <GridFlex
                        crossStyle={crossStyle}
                        rule={"grid10B"}
                        content10={
                            "Abonnez-vous à notre newsletter"
                        }
                    >
                    </GridFlex> 
                </>
            }
        </section>
    )
}

export default Content