import React from "react";

const Image = ({
    rule, source, link, className,imageA
}) => {
    return (
        <>
            {
                (rule == "image1") &&

                <img
                    className={"img-fluid"}
                    src={source}
                />
            }

            {
                (rule == "image2") &&

                <img
                    className={`elementHeader2 ${imageA}`}
                    src={source}
                />
            }

            {
                (rule == "image12") &&

                <img
                    style={{maxWidth: "60px",height:"60px"}}
                    className="rounded-1"
                    src={source}
                />
            }

            {
                (rule == "image3") &&

                <img
                    className="img-fluid"
                    src={source}
                />
            }

            {
                (rule == "image6") &&

                <img
                    width={"50%"}
                    height={"auto"}
                    src={source}
                />
            }

            {
                (rule == "image4") &&

                <img
                    className={"elementHeader4"}
                    src={source}
                />
            }

            {
                (rule == "img-s") &&

                <img
                    src={link}
                    className={className}
                />
            }

        </>
    );
}

export default Image;