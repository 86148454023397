import React, { useEffect, useState } from "react"

const useOperationEffect = (anim,defineClass) => {
    const [test, setTest] = useState({
        ident: defineClass
    })
    const seuilNumber = 20
    let prevRatio = 0.0
    let div

    useEffect(() => {
        div = document.querySelectorAll(`.${defineClass}`)
        
        div.forEach(par => {
            createObserver(par)
        })
    },[])

    const createObserver = (par) => {
        let observator

        let options = {
            root: null,
            rootMargin: "0px",
            threshold: buildThresholdList()
        }

        
        observator = new IntersectionObserver(handleIntersect,options)

        
        observator.observe(par)

    }


    const handleIntersect = (entries,observator) => {
        entries.forEach((entry) => {
            if (
                entry.target.classList.contains(`${defineClass}`) && 
                entry.intersectionRatio > prevRatio
            )
            {
                setTest({...test,ident: anim})
            }
            // else {
            //     setTest({...test,ident: ''})
            //     console.log("No")
            // }
            prevRatio = entry.intersectionRatio

        })
    }


    const buildThresholdList = () => {
        let thresholds = []
        let numSteps = 50

        for (let i = 1.0; i < numSteps; i++) {
            let ratio = i / numSteps
            thresholds.push(ratio)
        }

        thresholds.push(0)
        return thresholds
    }

    return test.ident
    

}


export default useOperationEffect
