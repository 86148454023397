import React, { useState } from "react";
import { Link } from "react-router-dom";
import Style from "../Style/Style";


const Carousel = ({
    rule,
}) => {

    const [myStyle1, setMyStyle1] = useState({
        gcs: Style.grid2Cs,
        gcsa: Style.grid2CsA,
        gcsb: Style.grid2CsB,
        gcsc: Style.grid2CsC,
        gcs1: Style.grid2CsChild1,
        gcs2: Style.grid2CsChild2,
        gcs3: Style.grid2CsChild3,
        gcs4: Style.grid2CsChild4,
        gcs5: Style.grid2CsChild5,
        gcs6: Style.grid2CsChild6,
        gcs7: Style.grid2CsChild7,
        gcs8: Style.grid2CsChild8,
        bt1: "gridLinkChild1",
        bt2: "gridLinkChild2",
        flex: Style.f,
        flex1: Style.f1,
        flex2: Style.f2,
        flex3: Style.f3,
    })


    const hoverBt1 = () => {

        setMyStyle1({
            ...myStyle1,
            bt1: 'bt1-a',
        })
    }


    const outBt1 = () => {
        setMyStyle1({
            ...myStyle1,
            bt1: 'bt1-b',
        })
    }

    const hoverBt2 = () => {

        setMyStyle1({
            ...myStyle1,
            bt2: 'bt2-a',
        })
    }


    const outBt2 = () => {
        setMyStyle1({
            ...myStyle1,
            bt2: 'bt2-b',
        })
    }

    const[x,setX] = useState(0)

    const prevClick = (e) => {
        e.preventDefault()
        setTimeout(() => {
            setX(x - 1)
        }, 1500);
        if(x == 0){
            setTimeout(() => {
                setX(0)
            }, 1500);
        }
    }

    const nextClick = (e) => {
        e.preventDefault()
        setTimeout(() => {
            setX(x + 1)
        }, 1500);
        if(x == 2){
            setTimeout(() => {
                setX(2)
            }, 1500);
        }
    }


    if(rule == "image-carousel"){
        return (
            <>
                <section className="container mt-5">
                    <div className="row text-center d-flex align-items-center justify-content-center">
                    <div id="carouselImageInterval" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="10000">
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-1.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-2.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-3.png"
                                        >
                                        </img>
                                    </Link>
                                </div>
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-4.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-5.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-6.png"
                                        >
                                        </img>
                                    </Link>
                                </div>
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-7.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-8.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src=""
                                        >
                                        </img>
                                    </Link>
                                </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2000">
                                        <div className="row row-cols-md-3 my-2">
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-1.png"
                                            >
                                            </img>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-2.png"
                                            >
                                            </img>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-3.png"
                                            >
                                            </img>
                                        </Link>
                                    </div>
                                    <div className="row row-cols-md-3 my-2">
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-4.png"
                                            >
                                            </img>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-5.png"
                                            >
                                            </img>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-6.png"
                                            >
                                            </img>
                                        </Link>
                                    </div>
                                        </div>
                                        <div className="carousel-item">
                                        <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-1.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-2.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-3.png"
                                        >
                                        </img>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselImageInterval" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselImageInterval" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    </div>
                </section>
            </>
        );
    }

    if(rule == "video-carousel"){
        return (
            <>
                <section className="container">
                    <div className="row text-center d-flex align-items-center justify-content-center">
                    <div id="carouselVideoInterval" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="10000">
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-1.png"
                                        />
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-2.png"
                                        />
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-3.png"
                                        />
                                    </Link>
                                </div>
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-4.png"
                                        />
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-5.png"
                                        />
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-6.png"
                                        />
                                    </Link>
                                </div>
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-7.png"
                                        />
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-8.png"
                                        />
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src=""
                                        />
                                    </Link>
                                </div>
                                        </div>
                                        <div className="carousel-item" data-bs-interval="2000">
                                        <div className="row row-cols-md-3 my-2">
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <video
                                                className="img-fluid"
                                                src="../../images/ga-1.png"
                                            />
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <video
                                                className="img-fluid"
                                                src="../../images/ga-2.png"
                                            />
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <video
                                                className="img-fluid"
                                                src="../../images/ga-3.png"
                                            />
                                        </Link>
                                    </div>
                                    <div className="row row-cols-md-3 my-2">
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <video
                                                className="img-fluid"
                                                src="../../images/ga-4.png"
                                            />
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <video
                                                className="img-fluid"
                                                src="../../images/ga-5.png"
                                            />
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <video
                                                className="img-fluid"
                                                src="../../images/ga-6.png"
                                            />
                                        </Link>
                                    </div>
                                        </div>
                                        <div className="carousel-item">
                                        <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-1.png"
                                        />
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-2.png"
                                        />
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <video
                                            className="img-fluid"
                                            src="../../images/ga-3.png"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselVideoInterval" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselVideoInterval" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Carousel;






{/* <div className="d-sm-none col-md-2">
                        <Link
                            to={""}
                            onClick={prevClick}
                            onMouseOver={hoverBt1}
                            onMouseOut={outBt1}
                            id="hover-grid-link-child2"
                            className={myStyle1.bt1}
                        >
                            Prev
                        </Link>
                    </div> */}
                    {/* <div className="col-md-8">

                        {
                            (x == 0) &&
                            <>
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-1.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-2.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-3.png"
                                        >
                                        </img>
                                    </Link>
                                </div>
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-4.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-5.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-6.png"
                                        >
                                        </img>
                                    </Link>
                                </div>
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-7.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-8.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src=""
                                        >
                                        </img>
                                    </Link>
                                </div>

                                
                            </>
                        }
                        
                        {
                        
                            (x == 1)&&
            
                                <>
                                    <div className="row row-cols-md-3 my-2">
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-1.png"
                                            >
                                            </img>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-2.png"
                                            >
                                            </img>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-3.png"
                                            >
                                            </img>
                                        </Link>
                                    </div>
                                    <div className="row row-cols-md-3 my-2">
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-4.png"
                                            >
                                            </img>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-5.png"
                                            >
                                            </img>
                                        </Link>
                                        <Link
                                            to={"/"}
                                            className="col hover-grid-link"
                                        >
                                            <img
                                                className="img-fluid"
                                                src="../../images/ga-6.png"
                                            >
                                            </img>
                                        </Link>
                                    </div>
                                </>
                            }
                            
                            {
                            (x == 2) &&

                            <>
                                <div className="row row-cols-md-3 my-2">
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-1.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-2.png"
                                        >
                                        </img>
                                    </Link>
                                    <Link
                                        to={"/"}
                                        className="col hover-grid-link"
                                    >
                                        <img
                                            className="img-fluid"
                                            src="../../images/ga-3.png"
                                        >
                                        </img>
                                    </Link>
                                </div>
                            </>
                        }
                    </div> */}
                    {/* <div className="d-sm-none d-md-none col-md-2">
                        <Link
                            to={""}
                            onClick={nextClick}
                            onMouseOver={hoverBt2}
                            onMouseOut={outBt2}
                            id="hover-grid-link-child2"
                            className={myStyle1.bt2}
                        >
                            Next
                        </Link>
                    </div> */}