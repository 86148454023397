import React, { useState } from "react";
import { Link } from "react-router-dom";
import Style from "../Style/Style";
import List from "./List";


const Header = ({
    rule,
}) => {


    
    const [myStyle, setMyStyle] = useState({
        myStyle1: "linkStyle1",
        myStyle2: "linkStyle1",
        myStyle3: "linkStyle1",
        myStyle9: "linkStyle1",
        myStyle10: "linkStyle1",
        myStyle12: "linkStyle1",
        myStyle15: "linkStyle1",
        myStyle4: "header",
        myStyle5: "headerChild1",
        myStyle6: "headerChild2",
        myStyle7: "headerChild3",
        myStyle8: "listStyle1",
        myStyle13: "linkStyle1",
        myStyle14: "linkStyle1",
        myStyle76: "headerChild1-e1",
    })

    const [timeOutId,setTimeOutId] = useState(null)

    
    const headerAnim = () => {

        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setMyStyle({
                    ...myStyle,
                    myStyle4: "myStyle4"
                })
            }
            else{
                setMyStyle({
                    ...myStyle,
                    myStyle4: 'header'
                })
            }

            if (window.scrollTo > 0) {
                setMyStyle({
                    ...myStyle,
                    myStyle4: 'header'
                })
            }
        })
    }
    headerAnim()

    // const clickLink = (e) => {
    //     e.preventDefault()
    //     var id = document.querySelectorAll('.click')
    //     id[0].classList.add('.click-color')
    //     id[1].classList.add('.click-color')
    //     id[2].classList.add('.click-color')
    //     id[3].classList.add('.click-color')
    //     id[4].classList.add('.click-color')
    //     id[5].classList.add('.click-color')
    //     id[6].classList.add('.click-color')
    // }

    const hoverLink1 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle1: 'myStyleY-a'
        })
    }

    const outLink1 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle1: 'myStyleY-b'
            })
        }, 0))
    }
    const hoverLink2 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle2: 'myStyleY-a'
        })
    }

    const outLink2 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle2: 'myStyleY-b'
            })
        }, 0))
    }
    const hoverLink3 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle3: 'myStyleY-a'
        })
    }

    const outLink3 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle3: 'myStyleY-b'
            })
        }, 0))
    }

    const hoverLink9 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle9: 'myStyleY-a'
        })
    }

    const outLink9 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle9: 'myStyleY-b'
            })
        }, 0))
    }

    const hoverLink10 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle10: 'myStyleY-a'
        })
    }

    const outLink10 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle10: 'myStyleY-b'
            })
        }, 0))
    }

    const hoverLink14 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle14: 'myStyleY-a'
        })
    }

    const outLink14 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle14: 'myStyleY-b'
            })
        }, 0))
    }

    const hoverLink13 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle13: 'myStyleY-a'
        })
    }

    const outLink13 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle13: 'myStyleY-b'
            })
        }, 0))
    }

    const hoverLink12 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle12: 'myStyleY-a'
        })
    }

    const outLink12 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle12: 'myStyleY-b'
            })
        }, 0))
    }

    const hoverLink15 = () => {
        clearTimeout(timeOutId)
        setMyStyle({
            ...myStyle,
            myStyle15: 'myStyleY-a'
        })
    }

    const outLink15 = () => {
        setTimeOutId(() => setTimeout(() => {
            setMyStyle({
                ...myStyle,
                myStyle15: 'myStyleY-b'
            })
        }, 0))
    }


    return(
        <>
            {
                rule == "form-header-a" ?
                <>
                <header className={myStyle.myStyle4}>
                    <div className={myStyle.myStyle7}>
                        <img 
                            className="hover-opacity-2"
                            src="../images/rs_b.png"
                        />
                    </div>
                    <div
                        className={myStyle.myStyle5}
                    >
                        
                        <List
                            className={myStyle.myStyle8}
                            rule={"list1"}
                            // list1={
                            //     <>
                            //         <Link
                            //             to={"/"}
                            //             className={myStyle.myStyle1}
                            //             onMouseOver={hoverLink1}
                            //             onMouseOut={outLink1}
                            //             // onClick={clickLink}
                            //             // className="click"
                            //         >
                            //             <strong>ACCUEIL</strong>
                            //         </Link>
                            //     </>
                            // }
                            list1={
                                <>
                                    <Link
                                        to={"/royal-sale-production"}
                                        className={myStyle.myStyle1}
                                        onMouseOver={hoverLink1}
                                        onMouseOut={outLink1}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <img 
                                            className="hover-opacity-1"
                                            src="../images/rs_b.png"
                                        />
                                    </Link>
                                </>
                            }
                            list7={
                                <>
                                    <Link
                                        to={"/royal-sale-production/rs-hcm"}
                                        className={myStyle.myStyle14}
                                        onMouseOver={hoverLink14}
                                        onMouseOut={outLink14}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>RS HCM</strong>
                                    </Link>
                                </>
                            }
                            // list4={
                            //     <>
                            //         <Link
                            //             to={"/royal-sale-production"}
                            //             className={myStyle.myStyle9}
                            //             onMouseOver={hoverLink9}
                            //             onMouseOut={outLink9}
                            //             // onClick={clickLink}
                            //             // className="click"
                            //         >
                            //             <strong>ROYAL SALE PRODUCTION</strong>
                            //         </Link>
                            //     </>
                            // }
                            list6={
                                <>
                                    <Link
                                        to={"/royal-sale-production/rs-music"}
                                        className={myStyle.myStyle13}
                                        onMouseOver={hoverLink13}
                                        onMouseOut={outLink13}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>RS MUSIC</strong>
                                    </Link>
                                </>
                            }
                            list8={
                                <>
                                    <Link
                                        to={"/royal-sale-production/rs-com"}
                                        className={myStyle.myStyle12}
                                        onMouseOver={hoverLink12}
                                        onMouseOut={outLink12}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>RS COM</strong>
                                    </Link>
                                </>
                            }
                            list15={
                                <>
                                    <Link
                                        to={"/royal-sale-production/avis-aux-clients"}
                                        className={myStyle.myStyle15}
                                        onMouseOver={hoverLink15}
                                        onMouseOut={outLink15}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>AVIS CLIENTS</strong>
                                    </Link>
                                </>
                            }
                            list5={
                                <>
                                    <Link
                                        to={"/royal-sale-production/arkos"}
                                        className={myStyle.myStyle10}
                                        onMouseOver={hoverLink10}
                                        onMouseOut={outLink10}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>ARKOS</strong>
                                    </Link>
                                </>
                            }
                            list2={
                                <>
                                    <Link
                                        to={"/royal-sale-production/histoire"}
                                        className={myStyle.myStyle2}
                                        onMouseOver={hoverLink2}
                                        onMouseOut={outLink2}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>HISTOIRE</strong>
                                    </Link>
                                </>
                            }
                            // list2={
                            //     <>
                            //         <Link
                            //             to={"/royal-sale-production/stream-buy"}
                            //             className={myStyle.myStyle2}
                            //             onMouseOver={hoverLink2}
                            //             onMouseOut={outLink2}
                            //             // onClick={clickLink}
                            //             // className="click"
                            //         >
                            //             <strong>SERVICES</strong>
                            //         </Link>
                            //     </>
                            // }
                            list3={
                                <>
                                    <Link
                                        to={"/royal-sale-production/contactez-nous"}
                                        className={myStyle.myStyle3}
                                        onMouseOver={hoverLink3}
                                        onMouseOut={outLink3}
                                        // onClick={clickLink}
                                        // className="click"
                                    >
                                        <strong>CONTACTEZ-NOUS</strong>
                                    </Link>
                                </>
                            }
                        >
                        </List>
                    </div>
                    <div className={myStyle.myStyle6}>
                        <i
                            onClick={(e) => {
                                e.preventDefault()

                                setMyStyle({
                                    ...myStyle,
                                    myStyle6: 'd--none',
                                    myStyle76: 'headerChild1-e2 animate__animated animate__slideInRight',
                                    myStyle8: 'listStyle1-1',
                                })
                            }}
                            className="bi bi-justify text-white fz"
                        ></i>
                    </div>
                    
                    
                </header> 
                <div
                    className={myStyle.myStyle76}
                >
                    <i
                        onClick={(e) => {
                            e.preventDefault()

                            setMyStyle({
                                ...myStyle,
                                myStyle6: 'headerChild2',
                                myStyle8: 'listStyle1',
                                myStyle76: 'animate__animated animate__slideOutRight headerChild1-e1',
                            })
                        }}
                        className="bi bi-x-lg text-start px-2 text-white fz"
                    ></i>
                    <List
                        className={myStyle.myStyle8}
                        rule={"list1"}
                        // list1={
                        //     <>
                        //         <Link
                        //             to={"/"}
                        //             className={myStyle.myStyle1}
                        //             onMouseOver={hoverLink1}
                        //             onMouseOut={outLink1}
                        //             // onClick={clickLink}
                        //             // className="click"
                        //         >
                        //             <strong>ACCUEIL</strong>
                        //         </Link>
                        //     </>
                        // }
                        list1={
                            <>
                                <Link
                                    to={"/royal-sale-production"}
                                    className={myStyle.myStyle1}
                                    onMouseOver={hoverLink1}
                                    onMouseOut={outLink1}
                                    // onClick={clickLink}
                                    // className="click"
                                >
                                    <img 
                                        // style={{
                                        //     marginTop: "2px",
                                        //     maxWidth:"40px",height:"40px",border:"1px solid transparent",
                                        //     borderRadius:"40px",borderWidth:"2px 2px 2px 2px",borderColor:"white"
                                        // }} 
                                        className="hover-opacity-1"
                                        src="../images/rs_b.png"
                                            
                                    />
                                </Link>
                            </>
                        }
                        list7={
                            <>
                                <Link
                                    to={"/royal-sale-production/rs-hcm"}
                                    className={myStyle.myStyle14}
                                    onMouseOver={hoverLink14}
                                    onMouseOut={outLink14}
                                    // onClick={clickLink}
                                    // className="click"
                                >
                                    <strong>RS HCM</strong>
                                </Link>
                            </>
                        }
                        // list4={
                        //     <>
                        //         <Link
                        //             to={"/royal-sale-production"}
                        //             className={myStyle.myStyle9}
                        //             onMouseOver={hoverLink9}
                        //             onMouseOut={outLink9}
                        //             // onClick={clickLink}
                        //             // className="click"
                        //         >
                        //             <strong>ROYAL SALE PRODUCTION</strong>
                        //         </Link>
                        //     </>
                        // }
                        list6={
                            <>
                                <Link
                                    to={"/royal-sale-production/rs-music"}
                                    className={myStyle.myStyle13}
                                    onMouseOver={hoverLink13}
                                    onMouseOut={outLink13}
                                    // onClick={clickLink}
                                    // className="click"
                                >
                                    <strong>RS MUSIC</strong>
                                </Link>
                            </>
                        }
                        list8={
                            <>
                                <Link
                                    to={"/royal-sale-production/rs-com"}
                                    className={myStyle.myStyle12}
                                    onMouseOver={hoverLink12}
                                    onMouseOut={outLink12}
                                    // onClick={clickLink}
                                    // className="click"
                                >
                                    <strong>RS COM</strong>
                                </Link>
                            </>
                        }
                        list15={
                            <>
                                <Link
                                    to={"/royal-sale-production/avis-aux-clients"}
                                    className={myStyle.myStyle15}
                                    onMouseOver={hoverLink15}
                                    onMouseOut={outLink15}
                                    // onClick={clickLink}
                                    // className="click"
                                >
                                    <strong>AVIS CLIENTS</strong>
                                </Link>
                            </>
                        }
                        list5={
                            <>
                                <Link
                                    to={"/royal-sale-production/arkos"}
                                    className={myStyle.myStyle10}
                                    onMouseOver={hoverLink10}
                                    onMouseOut={outLink10}
                                    // onClick={clickLink}
                                    // className="click"
                                >
                                    <strong>ARKOS</strong>
                                </Link>
                            </>
                        }
                        list2={
                            <>
                                <Link
                                    to={"/royal-sale-production/histoire"}
                                    className={myStyle.myStyle2}
                                    onMouseOver={hoverLink2}
                                    onMouseOut={outLink2}
                                    // onClick={clickLink}
                                    // className="click"
                                >
                                    <strong>HISTOIRE</strong>
                                </Link>
                            </>
                        }
                        // list2={
                        //     <>
                        //         <Link
                        //             to={"/royal-sale-production/stream-buy"}
                        //             className={myStyle.myStyle2}
                        //             onMouseOver={hoverLink2}
                        //             onMouseOut={outLink2}
                        //             // onClick={clickLink}
                        //             // className="click"
                        //         >
                        //             <strong>SERVICES</strong>
                        //         </Link>
                        //     </>
                        // }
                        list3={
                            <>
                                <Link
                                    to={"/royal-sale-production/contactez-nous"}
                                    className={myStyle.myStyle3}
                                    onMouseOver={hoverLink3}
                                    onMouseOut={outLink3}
                                    // onClick={clickLink}
                                    // className="click"
                                >
                                    <strong>CONTACTEZ-NOUS</strong>
                                </Link>
                            </>
                        }
                    >
                    </List>
                </div>
                </>
                :

                ""
            }
        </>
    );
}

export default Header;